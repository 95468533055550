<template>
  <div class="match-detail__header-wrapper">
    <div class="match-detail__header-info">
      <div class="match-detail__header-info__match" v-if="data.leagueName !== null">
        {{ data.leagueName }}
      </div>
      <div class="match-detail__header-info__country">{{ data.country }}</div>
    </div>
    <div class="match-detail__header-info__opponent display-flex-center">
      <Navigation
        :id="data.homeTeamId"
        :category="'team'"
        :name="data.homeTeamName"
        class="display-flex-sb"
      >
        <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.homeTeamId}`}" class="display-flex-sb"> -->
        <div class="display-flex-center ">
          <div class="mr-1rem match-detail__header-info__home-name">
            {{ data.homeTeamName ? data.homeTeamName : "TBD" }}
          </div>
          <Icon :logoUrl="data.homeLogo"></Icon>
        </div>
      </Navigation>
      <!-- </router-link > -->
      <div class="mr-2rem ml-2rem match-detail__header-info__score-wrapper">
        <div class="display-flex-center">
          <div :class="[data.homeTeamScore >= 100 ? 'match-detail__header-info__score-100' : 'match-detail__header-info__score']">
            {{ data.homeTeamScore }}
          </div>
          <div>:</div>
          <div :class="[data.awayTeamScore >= 100 ? 'match-detail__header-info__score-100' : 'match-detail__header-info__score']">
            {{ data.awayTeamScore }}
          </div>
        </div>
        <!--  && !data.matchStatus.includes(':') -->
        <div
          v-if="data.matchStatus"
          class="match-detail__header-info__match-status display-flex-center"
        >
          {{ matchPrimaryStatus }}
        </div>
      </div>

      <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.awayTeamId}`}"  class="display-flex-sb"> -->
      <Navigation
        :id="data.awayTeamId"
        :category="'team'"
        :name="data.awayTeamName"
        class="display-flex-sb"
      >
        <div class="display-flex-center">
          <Icon :logoUrl="data.awayLogo" class="mr-1rem"></Icon>
          <div class="match-detail__header-info__away-name">
            {{ data.awayTeamName ? data.awayTeamName : "TBD" }}
          </div>
        </div>
        <!-- </router-link>    -->
      </Navigation>
    </div>

    <div class="match-detail__header-info__penatly-score-wrapper">
      <div
        class="match-detail__header-info__penatly display-flex-center"
        style="margin-bottom:0"
        v-if="data.awayTeamPenaltyScore"
      >
        <Navigation
          :id="data.homeTeamId"
          :category="'team'"
          :name="data.homeTeamName"
          class="display-flex-sb relative"
        >
          <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.homeTeamId}`}" class="display-flex-sb relative"> -->
          <div class="display-flex-center mr-1rem">
            <!-- style="width: 15rem" -->
            <div
              class="mr-1rem match-detail__header-info__home-name"              
            ></div>
            <div class="match-detail__header-info__penatly-label left">
              <img
                src="../../../static/images/label/label_pen_win.png"
                v-if="data.homeTeamPenaltyScore > data.awayTeamPenaltyScore"
              />
            </div>
            <!-- <Icon :category="'team'" :id=data.homeTeamId ></Icon> -->
          </div>
          <div class="match-detail__header-info__score" >
            {{ data.homeTeamPenaltyScore }}
          </div>
          <!-- </router-link > -->
        </Navigation>
        <div>:</div>
        <Navigation
          :id="data.awayTeamId"
          :category="'team'"
          :name="data.awayTeamName"
          class="display-flex-sb relative"
        >
          <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.awayTeamId}`}"  class="display-flex-sb relative"> -->
          <div class="mr-2rem match-detail__header-info__score">
            {{ data.awayTeamPenaltyScore }}
          </div>
          <div class="match-detail__header-info__penatly-label right">
            <img
              src="../../../static/images/label/label_pen_win.png"
              v-if="data.awayTeamPenaltyScore > data.homeTeamPenaltyScore"
            />
          </div>
          <div class="display-flex-center">
            <!-- <div class="icon-league mr-1rem"></div> -->
            <div class="match-detail__header-info__away-name"></div>
          </div>
          <!-- </router-link>              -->
        </Navigation>
      </div>
      <div
        class=" match-detail__header-info__penatly-title"
        v-if="data.awayTeamPenaltyScore"
      >
        {{ $t("PENALTY") }}
      </div>
    </div>
    
    <div class="match-detail__header-info__date" :class="'match-detail__header-info__date-'+currentLocale">
      {{ dateFormat(data.matchTime) }}
    </div>
    <!--  v-if="Object.keys(winRate).length > 0" -->
    <ScoreInfoWinRate :winRate="winRate" :detailOdds="detailOdds"></ScoreInfoWinRate>
    <!-- <div v-show="data.containLiveStream" class="w-100">
      <video
        id="videoLiveRef"
        class="video-js vjs-default-skin w-100"
        controls
        autoplay
      ></video>
    </div> -->
  </div>
</template>

<script>
import ScoreInfoWinRate from "@/components/matchDetail/ScoreInfoWinRate.vue";

import { mapGetters } from "vuex";
import config from "@/js/config.js";

export default {
  components: {
    ScoreInfoWinRate
  },
  props: {
    data: {
      type: Object,
    },
    winRate: {
      type: Object,
      default: {},
    },
    detailOdds: {
      type: Object
    }
    
  },

  computed: {
    ...mapGetters([
      "currentLocale",
      "currentSportType",
    ]),
  },
  watch: {
    data: {
      deep: true,
      handler(newVal, oldVal) {
        this.dateFormat();
        this.updateMatchStatus();
      },
    },
  },

  data() {
    return {
      matchDateDay: "",
      footballMatchStatusFullText: config.footballMatchStatusFullText,
      basketballMatchStatusFullText: config.basketballMatchStatusFullText,
      matchStatusFT: "FT",
      matchPrimaryStatus: "",
      cnLocaleList: config.cnLocaleList,
    };
  },
  mounted() {
    //this.dateFormat();
    // this.playVideoLive();
  },

  methods: {
    updateMatchStatus() {
      if (this.data.matchStatus) {
        if (this.data.matchStatus === "Pen.") {
          if (this.currentSportType === "football")
            this.matchPrimaryStatus = this.footballMatchStatusFullText[this.matchStatusFT];
        } else {
          let keysArr;
          if (this.currentSportType === "football")
            keysArr = Object.keys(this.footballMatchStatusFullText);
          else if (this.currentSportType === "basketball")
            keysArr = Object.keys(this.basketballMatchStatusFullText);
          if (keysArr.includes(this.data.matchStatus)) {
            if (this.currentSportType === "football")
              this.matchPrimaryStatus = this.footballMatchStatusFullText[this.data.matchStatus];
            else if (this.currentSportType === "basketball") {
              if (["Q1","Q2","Q3","Q4","TBD","Interr.","Canc.","Postp."].includes(this.data.matchStatus)) {
                this.matchPrimaryStatus = this.data.matchStatus;
              } else {
                this.matchPrimaryStatus = this.basketballMatchStatusFullText[this.data.matchStatus];
              }
            }
          } else {
            this.matchPrimaryStatus = this.data.matchStatus;
          }
        }
      }
    },
    dateFormat() {
      if (typeof this.data !== "undefined") {
        if (typeof this.data.matchTime !== "undefined") {
          let result;
          let matchDate = new Date(this.data.matchTime);
         
    
          if (this.currentLocale === 'vn') {
            result = 
              `${this.$i18n.t(this.$tools.getFullDay(matchDate.getDay()))},
               ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm
               ${matchDate.getFullYear()}, 
               ${matchDate.getHours()} giờ 
               ${(matchDate.getMinutes() < 10 ? "0": "") + matchDate.getMinutes()} phút`;
          } else if (this.cnLocaleList.includes(this.currentLocale)) {
              result = 
                `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()))}, 
                ${matchDate.getDate()}日  
                ${matchDate.getMonth() + 1}月
                ${matchDate.getFullYear()}年,
                ${(matchDate.getHours() < 10? "0" : "") + matchDate.getHours()}:
                ${(matchDate.getMinutes() < 10 ? "0" : "") + matchDate.getMinutes()}
                `;
          } else {
              result = 
                `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()) )},
                 ${matchDate.getDate()} 
                 ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))}
                 ${matchDate.getFullYear()}, 
                 ${(matchDate.getHours() < 10? "0" : "") + matchDate.getHours()}:
                 ${(matchDate.getMinutes() < 10 ? "0" : "") + matchDate.getMinutes()}
                `;
          }
         return result;
        }
      }
    },
  },
};
</script>

<style>
.match-detail__content-container {
  min-height: 50vh;
}
.match-detail__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.5rem;/*2rem*/
}

.match-detail__header-info {
  text-align: center;
  margin-bottom: 1rem;
}
.match-detail__header-info__match {
  font-size: 0.875rem;
}
.match-detail__header-info__country {
  font-size: 0.563rem;
}
.match-detail__header-info__date {
  color: var(--color-grey-light);
  font-size: 0.688rem;
 
}
.match-detail__header-info__date-kh{
   font-family: 'Khmer-400';
}
.match-detail__header-info__opponent {
  margin-bottom: 0.5rem;
}
.match-detail__header-info__match-status {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
  text-align: center;
}
.match-detail__header-info__home-name {
  width: 20rem;/*12rem*/
  font-size: 1.375rem;
  font-weight: 700;
  text-align: right;
  overflow: hidden;
}
.match-detail__header-info__home-name:hover {
  color: var(--color-hover);
}
.match-detail__header-info__away-name {
  width: 20rem;/*12rem*/
  font-size: 1.375rem;
  font-weight: 700;
  overflow: hidden;
}
.match-detail__header-info__away-name:hover {
  color: var(--color-hover);
}
.match-detail__header-info__penatly {
  border-top: 0.1rem solid #35454d;
  margin-top: 0.6rem;
  padding-top: 0.5rem;
  width: 37.5rem;
}
.match-detail__header-info__penatly-title {
  font-size: 0.875rem;/*0.7rem;*/
  color: var(--color-grey-light);
  margin-bottom: 1rem;
  line-height: 1.125rem;
  font-weight: 700;
}

.match-detail__header-info__penatly-label {
  top: 0.6rem;

  position: absolute;
  width: 2.3rem;
}
.match-detail__header-info__penatly-label.left {
  right: 3.4rem;
}
.match-detail__header-info__penatly-label.right {
  left: 3.4rem;
}
.match-detail__header-info__score-wrapper {
  font-size: 1.375rem;
  font-weight: 700;
  width: 7.5rem;
}
.match-detail__header-info__penatly-score-wrapper {
  font-size: 1.375rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match-detail__header-info__score {
  width: 2rem;
  text-align: center;
}
.match-detail__header-info__score-100 {
  width: 3rem;
  text-align: center;
}
.match-detail__header-info__livevideo {
  width: 100%;
  height: auto;
}
</style>
