<template>
  <iframe id="player" :src="theUrl" frameborder="0" allowfullscreen></iframe>
</template>

<script>
const tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
debugger
const firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

export default {
  props: {
    ytEmbedLink: {
      type: String,
      required: true,
    },
    playPauseVideo: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    ytEmbedLink: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.theUrl = newVal + "?enablejsapi=1&autoplay=1";
          this.$nextTick(() => this.loadYT());
        }
      },
    },
    playPauseVideo: {
      handler(newVal, oldVal) {
        this.onPlayerReady();
      },
    },
  },
  data() {
    return {
      player: null,
      playerState: null,
      theUrl: "",
    };
  },
  methods: {
    loadYT() {
      if (this.player) {
        this.player.destroy();
      }
      this.player = new YT.Player('player', {
        events: {
          onReady: this.onPlayerReady,
        },
      });
    },

    onPlayerReady(event) {
      if (this.playPauseVideo && this.player)
        this.player.playVideo();
      else if (!this.playPauseVideo && this.player)
        this.player.pauseVideo();
    },
  },
};
</script>
