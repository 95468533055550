<template>
  <div class="match-detail__header-wrapper">
    <!-- <div class="match-detail__header-info">
      <div class="match-detail__header-info__match" v-if="data.leagueName !== null">
        {{ data.leagueName }}
      </div>
      <div class="match-detail__header-info__country">{{ data.country }}</div>
    </div> -->
    <div
      class="match-detail__header-info__opponent"
      :class="{ 'display-flex-align-end': data.matchState > 0, 'display-flex-align-center': data.matchState <= 0 }"
    >
      <Navigation
        :id="data.homeTeamId"
        :category="'team'"
        :name="data.homeTeamName"
        class="display-flex-sb"
      >
        <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.homeTeamId}`}" class="display-flex-sb"> -->
        <div class="display-flex-center flex-col match-detail__team-info-wrapper">
          <Icon :logoUrl="data.homeLogo"></Icon>
          <div class="match-detail__header-info__penatly-label right">
            <img
                src="../../../static/images/label/label_short_pen_win.png"
                v-if="data.homeTeamPenaltyScore > data.awayTeamPenaltyScore"
            />
          </div>
          <div class="match-detail__header-info__home-name">
            {{ data.homeTeamName ? data.homeTeamName : "TBD" }}
          </div>
        </div>
      </Navigation>
      <!-- </router-link > -->

      <!-- Start: Match to be started / Canc. / Postponed etc. -->
      <div
        v-if="data.matchState <= 0 && data.matchState !== -1"
        class="mr-1rem ml-1rem match-detail__header-info__score-wrapper"
      >
        <!--  && !manualRefreshMatchDetailScore -->
        <div 
          class="display-flex-center"
          v-show="data.matchStatus"
        >
          <!-- <div class="match-detail__header-info__score"> -->
            {{ data.matchStatus }}
          <!-- </div> -->
        </div>
        <!-- <div 
          class="display-flex-center skeleton-match-detail__wrapper"
          v-show="manualRefreshMatchDetailScore"
        >
          <div class="skeleton-child skeleton-match-detail__score"></div>
        </div> -->
      </div>
      <!-- End: Match to be started / Canc. / Postponed etc.  -->


      <!-- Start: Normal Match Progress -->
      <div
        v-else-if="data.matchState > 0 || data.matchState === -1"
        class="mr-1rem ml-1rem match-detail__header-info__score-wrapper morning"
      >
      <!--  && !data.matchStatus.includes(':') -->
        <div
          v-if="data.matchStatus"
          class="match-detail__header-info__match-status display-flex-center"
        >
          {{ matchPrimaryStatus }}
        </div>
        <!--  && !manualRefreshMatchDetailScore -->
        <div 
          class="display-flex-center"
          v-show="homeTeamScore !== '' && awayTeamScore !== ''"
        >
          <div :class="[homeTeamScore >= 100 ? 'match-detail__header-info__score-100' : 'match-detail__header-info__score']">
            {{ homeTeamScore }}
          </div>
          <div :class="{'match-detail__header-info__score-divider': homeTeamScore >= 100 || awayTeamScore >= 100}">-</div>
          <div :class="[awayTeamScore >= 100 ? 'match-detail__header-info__score-100' : 'match-detail__header-info__score']">
            {{ awayTeamScore }}
          </div>
        </div>
        <!-- <div 
          class="display-flex-center skeleton-match-detail__wrapper"
          v-show="manualRefreshMatchDetailScore"
        >
          <div class="skeleton-child skeleton-match-detail__score"></div>
          <div>-</div>
          <div class="skeleton-child skeleton-match-detail__score"></div>
        </div> -->
        <div
          class="match-detail__header-secondary_info display-flex-center mt-s"
          v-show="this.currentSportType !== 'basketball'"
        >
          {{ matchSecondaryStatus }}
        </div>
      </div>
      <!-- End: Normal Match Progress -->


      <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.awayTeamId}`}"  class="display-flex-sb"> -->
      <Navigation
        :id="data.awayTeamId"
        :category="'team'"
        :name="data.awayTeamName"
        class="display-flex-sb"
      >
        <div class="display-flex-center flex-col match-detail__team-info-wrapper">
          <Icon :logoUrl="data.awayLogo"></Icon>
          <div class="match-detail__header-info__penatly-label left">
            <img
              src="../../../static/images/label/label_short_pen_win.png"
              v-if="data.awayTeamPenaltyScore > data.homeTeamPenaltyScore"
            />
          </div>
          <div class="match-detail__header-info__away-name">
            {{ data.awayTeamName ? data.awayTeamName : "TBD" }}
          </div>
        </div>
        <!-- </router-link>    -->
      </Navigation>
    </div>

  </div>
  <!--  v-if="Object.keys(winRate).length > 0" -->
    <ScoreInfoWinRate :winRate="winRate" :detailOdds="detailOdds"></ScoreInfoWinRate>

</template>

<script>
import ScoreInfoWinRate from "@/components/matchDetail/ScoreInfoWinRate.vue";

import { mapGetters } from "vuex";
import config from "@/js/config.js";

export default {
  components: {
    ScoreInfoWinRate
  },
  props: {
    data: {
      type: Object,
    },
    winRate: {
      type: Object,
      default: {},
    },
    detailOdds: {
      type: Object
    }
  },

  computed: {
    ...mapGetters([
      "currentLocale",
      "currentSportType",
      "manualRefreshMatchDetailScore"
    ]),
  },
  watch: {
    data: {
      deep: true,
      handler(newVal, oldVal) {
        // this.dateFormat();
        this.updatePrimaryMatchStatus();
        this.updateSecondaryMatchStatus();
      },
    },
  },

  data() {
    return {
      matchDateDay: "",
      footballMatchStatusFullText: config.footballMatchStatusFullText,
      basketballMatchStatusFullText: config.basketballMatchStatusFullText,
      matchStatusShortHalfTime: "H.T.",
      matchStatusShortFullTime: "F.T.",
      matchStatusShortPenalty: "Pen.",
      matchStatusShortAET: "AET.",
      matchPrimaryStatus: "",
      matchSecondaryStatus: "",
      homeTeamScore: "",
      awayTeamScore: "",
    };
  },
  mounted() {
    //this.dateFormat();
    // this.playVideoLive();
  },

  methods: {
    updateSportTypePrimaryMatchStatus(fullTextList) {
      let keysArr = Object.keys(fullTextList);
      if (this.data.matchStatus === "AET" || this.data.matchStatus === "Pen.") {
        this.matchPrimaryStatus = fullTextList["FT"];
      } else if (keysArr.includes(this.data.matchStatus)) {
        if (["Q1","Q2","Q3","Q4"].includes(this.data.matchStatus)) {
          this.matchPrimaryStatus = this.data.matchStatus;
          if (this.data.remainTime != "")
            this.matchPrimaryStatus += ` ${this.data.remainTime}'`;
        } else 
          this.matchPrimaryStatus = fullTextList[this.data.matchStatus];
      } else {
        this.matchPrimaryStatus = this.data.matchStatus;
      }
    },
    updatePrimaryMatchStatus() {
      if (this.data.matchStatus) {
        if (this.currentSportType === "football") {
          this.updateSportTypePrimaryMatchStatus(this.footballMatchStatusFullText);
        } else if (this.currentSportType === "basketball") {
          this.updateSportTypePrimaryMatchStatus(this.basketballMatchStatusFullText);
        }
        /**
         * Football - Match State
         * only cover matchState 1,2,3,4,-1 here
         * 1 - 1st half match
         * 2 - Half Time
         * 3 - 2nd half match
         * 4 - Extra Time
         * 5 - Ongoing Penalty
         * -1 - End of match, 3 main scenarios
         */
        if (this.currentSportType === "football") {
          if ([1,2,3,5,-1].includes(this.data.matchState)) {//this.data.matchState === 1
            this.homeTeamScore = this.data.homeTeamScore;
            this.awayTeamScore = this.data.awayTeamScore;
          } else if (this.data.matchState === 4) {
            this.homeTeamScore = this.data.homeTeamExtraTimeScore;
            this.awayTeamScore = this.data.awayTeamExtraTimeScore;
          }
        } else if (this.currentSportType === "basketball") {
          /** 
           * Basketball - Match State
            0：未开赛
            1：一节
            2：二节
            3：三节
            4：四节
            5：1'OT
            6：2'OT
            7：3'OT
            50：中场
            -1：完场
            -2：待定
            -3：中断
            -4：取消
            -5：推迟
          */
          if ([1,2,3,4,5,6,7,50,-1].includes(this.data.matchState)) {
            this.homeTeamScore = this.data.homeTeamScore;
            this.awayTeamScore = this.data.awayTeamScore;
          }
        }
        
      }
    },
    updateSecondaryMatchStatus() {
      if (this.currentSportType === "football") {
        // 2nd Half Time
        if (this.data.matchState === 3) {
          this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
        } else if (this.data.matchState === 4) {
          // Extra Time
          this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
        } else if (this.data.matchState === 5) {
          // Ongoing Penalty (temporary enabled)
          this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
        // } else if (this.data.matchState === 5) {
        //   // Ongoing Penalty
        //   // with Extra Time
        //   if (this.data.containExtraTime === true) {
        //     this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
        //   } else {
        //     // without Extra Time
        //     this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
        //   }
        } else if (this.data.matchState === -1) {
          // Full Time
          if (this.data.matchStatus === "FT") {
            // Normal Match End
            this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeTeamHalfScore} - ${this.data.awayTeamHalfScore}`;
          } else if (this.data.matchStatus === "AET") {
            // After Extra Time
            this.matchSecondaryStatus = `${this.matchStatusShortAET} ${this.data.homeTeamExtraTimeScore} - ${this.data.awayTeamExtraTimeScore}`;
          } else if (this.data.matchStatus === "Pen.") {
            // Penalty
            this.matchSecondaryStatus = `${this.matchStatusShortPenalty} ${this.data.homeTeamPenaltyScore} - ${this.data.awayTeamPenaltyScore}`;
          }
        }
      }
      // 26/01/2023 - BA (Julio) informed to remove "matchSecondaryStatus" element for basketball, as itis confusing
      //  else if (this.currentSportType === "basketball") {
      //   // Q2
      //   if (this.data.matchState === 2) {
      //     this.matchSecondaryStatus = `Q1. ${this.data.homeQ1Score} - ${this.data.awayQ1Score}`;
      //   } else if (this.data.matchState === 50) {
      //     // Half Time (Q2 ended)
      //     this.matchSecondaryStatus = `Q2. ${this.data.homeQ2Score} - ${this.data.awayQ2Score}`;
      //   } else if (this.data.matchState === 3) {
      //     // Q3
      //     this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeHalfTimeScore} - ${this.data.awayHalfTimeScore}`;
      //   } else if (this.data.matchState === 4) {
      //     // Q4
      //     this.matchSecondaryStatus = `Q3. ${this.data.homeQ3Score} - ${this.data.awayQ3Score}`;
      //   } else if (this.data.matchState === -1) {
      //     // Full Time
      //     this.matchSecondaryStatus = `${this.matchStatusShortHalfTime} ${this.data.homeHalfTimeScore} - ${this.data.awayHalfTimeScore}`;
      //   } else if ([5,6,7].includes(this.data.matchState)) {
      //     // Overtime
      //     this.matchSecondaryStatus = `${this.matchStatusShortFullTime} ${this.data.homeHalfTimeScore} - ${this.data.awayHalfTimeScore}`;
      //   }
      //   // OT concluded???
      // }
    },
    // dateFormat() {
    //   if (typeof this.data !== "undefined") {
    //     if (typeof this.data.matchTime !== "undefined") {
    //       let result;
    //       let matchDate = new Date(this.data.matchTime);
         
    
    //       if (this.currentLocale === 'vn') {
    //         result = 
    //           `${this.$i18n.t(this.$tools.getFullDay(matchDate.getDay()))},
    //            ${matchDate.getDate()} tháng ${matchDate.getMonth() + 1} năm
    //            ${matchDate.getFullYear()}, 
    //            ${matchDate.getHours()} giờ 
    //            ${(matchDate.getMinutes() < 10 ? "0": "") + matchDate.getMinutes()} phút`;
    //       } else {
    //           result = 
    //             `${this.$i18n.t(this.$tools.getDay(matchDate.getDay()) )},
    //              ${matchDate.getDate()} 
    //              ${this.$i18n.t(this.$tools.getMonth(matchDate.getMonth()))}
    //              ${matchDate.getFullYear()}, 
    //              ${(matchDate.getHours() < 10? "0" : "") + matchDate.getHours()}:
    //              ${(matchDate.getMinutes() < 10 ? "0" : "") + matchDate.getMinutes()}
    //             `;
    //       }
    //      return result;
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
.match-detail__content-container {
  min-height: 50vh;
}
.match-detail__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.5rem;/*2rem*/
}

.match-detail__header-info {
  text-align: center;
  margin-bottom: 1rem;
}
.match-detail__header-info__match {
  font-size: 0.875rem;
}
.match-detail__header-info__country {
  font-size: 0.563rem;
}
.match-detail__header-secondary_info {/*.match-detail__header-info__date*/
  color: var(--color-grey-80);
  font-size: 0.688rem;
  min-height: 0.849rem;
}
.match-detail__header-info__date-kh{
   font-family: 'Khmer-400';
}
.match-detail__header-info__opponent {
  margin-bottom: 0.5rem;
}
.match-detail__header-info__match-status {
  font-size: 0.688rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--bg-selected);
  border: 1px solid var(--bg-selected);
  border-radius: 10px;
  margin-bottom: 0.5rem;
}
.match-detail__team-info-wrapper {
  position: relative;
}
.match-detail__header-info__home-name,
.match-detail__header-info__away-name {
  width: 7.625rem;
  font-size: 0.688rem;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  color: var(--color-grey-light);
  margin-top: 0.8rem
}
.match-detail__header-info__home-name:hover,
.match-detail__header-info__away-name:hover {
  color: var(--color-hover);
}
.match-detail__header-info__penatly {
  border-top: 0.1rem solid #35454d;
  margin-top: 0.6rem;
  padding-top: 0.5rem;
}
.match-detail__header-info__penatly-title {
  font-size: 0.7rem;
  color: var(--color-grey-light);
  margin-bottom: 1rem;
}

.match-detail__header-info__penatly-label {
  top: 0.8rem;/*0.6rem*/

  position: absolute;
  width: 1.625rem;/*2.3rem*/
}
.match-detail__header-info__penatly-label.left {
  right: 5.5rem;/*3.4rem*/
}
.match-detail__header-info__penatly-label.right {
  left: 5.5rem;/*3.4rem*/
}
.match-detail__header-info__score-wrapper {
  font-size: 1.375rem;
  font-weight: 700;
  width: 4.375rem;
}
.match-detail__header-info__score {
  width: 2rem;
  text-align: center;
}
.match-detail__header-info__score-100 {
  width: 2.8rem;
  text-align: center;
}
.match-detail__header-info__score-divider {
  margin: 0 0.5rem;
}
.match-detail__header-info__livevideo {
  width: 100%;
  height: auto;
}
.skeleton-match-detail__wrapper{
  width: 100%;
  height: 100%;
  padding:0rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
/* .skeleton-match-detail__title{
  width: 50%;
  height: 1rem;
  margin-bottom: .25rem;
}
.skeleton-match-detail__content{
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
} */
.skeleton-match-detail__score{
  width: calc(100% - 0.6rem);
  height: 1.688rem;
  margin: 0 0.15rem;
  box-sizing: border-box;
  /* margin-bottom: 0.5rem; */
}
</style>
